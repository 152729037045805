import { useEffect, useState } from 'react';

import { useAuth } from '@zastrpay/auth';
import { LimitDirection, TransactionLimitsResponse, useTransactionLimits } from '@zastrpay/kyc-limits';
import { KycRequest, useKycRequest } from '@zastrpay/kyc-requests';

import { useApp } from '../AppProvider';
import { TransactionType } from '../auth/models';
import { featureToggles } from '../config';

export const getLimitDirection = (transactionType: TransactionType | undefined): LimitDirection => {
    if (!transactionType) {
        throw new Error('TransactionType is required to determine limit direction');
    }

    switch (transactionType) {
        case 'PassthroughDeposit':
        case 'CustomerToMerchantTransfer':
        case 'CustomerToMerchantPassthrough':
            return 'Credit';
        case 'PassthroughWithdrawal':
        case 'MerchantToCustomerTransfer':
        case 'MerchantToCustomerPassthrough':
            return 'Debit';
    }
};

type TransactionKycParams = {
    useCache?: boolean;
};

export const useTransactionKyc = ({ useCache = false }: TransactionKycParams) => {
    const { customerId } = useAuth();
    const { merchant, redirectSession } = useApp();

    const { pendingRequests, conditionalRequests, loaded: isKycLoaded, loadRequests } = useKycRequest();
    const { checkLimits } = useTransactionLimits();

    const [isChecksCompleted, setIsChecksCompleted] = useState(false);
    const [limits, setLimits] = useState<TransactionLimitsResponse | undefined>();
    const [limitKycRequests, setLimitKycRequests] = useState<KycRequest[]>([]);
    const [suggestedAmount, setSuggestedAmount] = useState<number | undefined>();

    const canLoad = customerId && merchant && redirectSession;

    useEffect(() => {
        // TODO: remove feature toggle after transaction monitors testing
        if (!featureToggles.limitsCheckEnabled) {
            setLimits({ exceededLimits: [], availableBalance: undefined });
            return;
        }

        if (canLoad && redirectSession.type === 'NewTransactionIntent') {
            const kycPromise = loadRequests();
            const limitsPromise = checkLimits(
                {
                    customerId,
                    sessionId: redirectSession.id,
                    amount: redirectSession.transactionData.amount,
                    currency: redirectSession.transactionData.currency,
                    limitDirection: getLimitDirection(redirectSession.transactionData.type),
                    merchantCategoryCode: merchant.categoryCode,
                },
                { useCache },
            );

            Promise.all([kycPromise, limitsPromise]).then(([_, limitsResponse]) => {
                setLimits(limitsResponse);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isKycLoaded && limits) {
            const limitKycRequests = limits.exceededLimits
                .map(({ limitId }) => {
                    const limitKycRequest = conditionalRequests.find((request) =>
                        request.referenceEntities.some((ref) => ref.id === limitId),
                    );
                    if (!limitKycRequest) {
                        console.error(`KycRequest not found for limitId: ${limitId}`);
                    }
                    return limitKycRequest;
                })
                .filter((request) => request !== undefined);

            const suggestedAmount = pendingRequests.length === 0 && limitKycRequests.length > 0 ? limits.availableBalance : undefined;

            setSuggestedAmount(suggestedAmount);
            setLimitKycRequests(limitKycRequests);
            setIsChecksCompleted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isKycLoaded, limits]);

    return { isChecksCompleted, pendingKycRequests: pendingRequests, limitKycRequests, suggestedAmount };
};
