import { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from '@zastrpay/auth';
import { useKycRequest } from '@zastrpay/kyc-requests';
import { TransactionIntent } from '@zastrpay/transaction-intents';
import { TransactionType } from '@zastrpay/transactions';

import { useApp } from '../AppProvider';
import { Direction, TransactionType as RedirectSessionTransactionType } from '../auth/models';
import { get as getIntent } from '../transaction-intents/api';

export type CustomerPermissionsState = {
    existingIntent?: TransactionIntent;
    isIntentAllowed: boolean;
};

export type CustomerPermissionsContext = CustomerPermissionsState & {};

const Context = createContext<CustomerPermissionsContext | undefined>(undefined);

type CustomerIntentData = {
    ready: boolean;
    type?: RedirectSessionTransactionType | TransactionType;
    direction?: Direction;
    existing?: TransactionIntent;
};

export const CustomerPermissionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { customer } = useAuth();
    const { redirectSession } = useApp();
    const { completed, loaded } = useKycRequest();

    const [data, setData] = useState<CustomerIntentData>({ ready: false });
    const [state, setState] = useState<CustomerPermissionsState>({
        isIntentAllowed: true,
    });

    useEffect(() => {
        setData({ ready: false });

        if (redirectSession?.type === 'NewTransactionIntent') {
            setData({
                ready: true,
                type: redirectSession.transactionData?.type,
                direction: redirectSession.transactionData?.direction,
            });
        } else if (redirectSession?.type === 'ExistingTransactionIntent') {
            getIntent(redirectSession.transactionIntentId).then((existing) => {
                setData({ ready: true, existing });
            });
        } else {
            setData({ ready: true });
        }
    }, [redirectSession]);

    const kycFlowCompleted = loaded && completed;

    useEffect(() => {
        if (!kycFlowCompleted || (!data?.type && !data?.direction)) {
            return setState((state) => ({
                ...state,
                isIntentAllowed: true,
            }));
        }

        switch (data.type ?? data.direction) {
            case 'PassthroughDeposit':
            case 'CustomerDeposit':
            case 'CustomerToMerchant':
            case 'CustomerToMerchantTransfer':
            case 'CustomerToMerchantPassthrough':
                return setState((state) => ({
                    ...state,
                    isIntentAllowed: customer?.permissions.executePassthroughDeposit !== 'Disallowed',
                }));
            case 'PassthroughWithdrawal':
            case 'CustomerWithdrawal':
            case 'MerchantToCustomer':
            case 'MerchantToCustomerPassthrough':
            case 'MerchantToCustomerTransfer':
                return setState((state) => ({
                    ...state,
                    isIntentAllowed: customer?.permissions.executePassthroughWithdrawal !== 'Disallowed',
                }));
            case undefined:
                return; // should never happen
        }
    }, [customer, data, kycFlowCompleted]);

    return <Context.Provider value={{ ...state, existingIntent: data.existing }}>{data.ready && children}</Context.Provider>;
};

export const useCustomerPermissions = (): CustomerPermissionsContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useCustomer must be used within an CustomerProvider');
    }

    return context;
};
