import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActiveKycRequest, isRequiredKycRequest, KycRequest, TransactionKycRequest } from '@zastrpay/kyc-requests';

import { useApp } from '../AppProvider';
import { featureToggles } from '../config';
import { useSessionNavigation } from '../layout/SessionNavigationProvider';
import { SuggestedAmountRetryDialog } from './SuggestedAmountRetryDialog';
import { useTransactionKyc } from './use-transaction-kyc';

type TransactionKycStep = {
    type: 'transaction-kyc';
    kycRequest: KycRequest;
    allKycRequests: KycRequest[];
};

type SkippableKycStep = {
    type: 'skippable-kyc';
    kycRequest: KycRequest;
};

type Step = TransactionKycStep | SkippableKycStep;

export const KycRequestPage: React.FC = () => {
    const { merchant } = useApp();
    const { cancel } = useSessionNavigation();

    const { isChecksCompleted, pendingKycRequests, limitKycRequests, suggestedAmount } = useTransactionKyc({ useCache: true });

    const [retryConfirmationOpen, setRetryConfirmationOpen] = useState(false);

    const [step, setStep] = useState<Step>();

    const navigate = useNavigate();

    const cancelSession = useCallback(() => cancel(), [cancel]);
    const backToMerchant = useCallback(() => {
        if (suggestedAmount) {
            setRetryConfirmationOpen(true);
        } else {
            cancelSession();
        }
    }, [suggestedAmount, cancelSession]);

    useEffect(() => {
        if (!isChecksCompleted) {
            return;
        }

        // TODO: remove feature toggle block when transaction limits are enabled in production
        if (!featureToggles.limitsCheckEnabled) {
            if (pendingKycRequests.length > 0) {
                setStep({ type: 'skippable-kyc', kycRequest: pendingKycRequests[0] });
            } else {
                navigate('/');
            }

            return;
        }

        const requiredRequests = pendingKycRequests.filter((request) => isRequiredKycRequest(request)).concat(limitKycRequests);
        const pendingManualReview = limitKycRequests.find((request: KycRequest) => request.state === 'PendingManualReview');

        if (pendingManualReview) {
            setStep({ type: 'transaction-kyc', kycRequest: pendingManualReview, allKycRequests: [] });
        } else if (requiredRequests.length > 0) {
            setStep({
                type: 'transaction-kyc',
                kycRequest: requiredRequests[0],
                allKycRequests: requiredRequests,
            });
        } else if (pendingKycRequests.length > 0) {
            setStep({ type: 'skippable-kyc', kycRequest: pendingKycRequests[0] });
        } else {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecksCompleted, limitKycRequests, pendingKycRequests]);

    switch (step?.type) {
        case 'transaction-kyc':
            return (
                <>
                    <SuggestedAmountRetryDialog
                        open={retryConfirmationOpen}
                        onOpenChange={setRetryConfirmationOpen}
                        amount={suggestedAmount}
                    />
                    <TransactionKycRequest
                        kycRequest={step.kycRequest}
                        allKycRequests={step.allKycRequests}
                        suggestedAmount={suggestedAmount}
                        merchantName={merchant?.displayName ?? merchant?.name}
                        onRetryClick={backToMerchant}
                    />
                </>
            );
        case 'skippable-kyc':
            return <ActiveKycRequest kycRequest={step.kycRequest} mode="page" skippable />;
        case undefined:
            return null;
    }
};
