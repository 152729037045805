import { lazy, useEffect } from 'react';
import { Route } from 'react-router';
import { Navigate, Routes, useNavigate } from 'react-router-dom';

import { trackPage, useCookieConsent } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { CurrentKycRequest } from '@zastrpay/kyc-requests';
import { Footer } from '@zastrpay/layout';

import { useApp } from './AppProvider';
import { VerifyEmail } from './auth/VerifyEmail';
import { identifyUser, resetUser } from './common/application-insights';
import { CustomerBlocked } from './common/CustomerBlocked';
import { GeneralError } from './common/GeneralError';
import { NetworkError } from './common/NetworkError';
import { SessionExpired } from './common/SessionExpired';
import { Support } from './common/Support';
import { KycRequestPage } from './kyc-request/KycRequestPage';
import { Layout } from './layout/Layout';
import { useSessionNavigation } from './layout/SessionNavigationProvider';
import { Login } from './login/Login';
import { Registration } from './registration/Registration';
import { RegistrationCompleted } from './registration/RegistrationCompleted';
import { TransactionIntentDetails } from './transaction-intents/TransactionIntentDetails';

const AnswerQuestionnaire = lazy(() => import('@zastrpay/kyc-questionnaires').then((module) => ({ default: module.AnswerQuestionnaire })));
const ChangeEmail = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangeEmail })));
const ChangePhone = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangePhone })));
const ChangeAuthFactorQrCodePage = lazy(() =>
    import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangeAuthFactorQrCodePage })),
);
const ChangePin = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangePin })));
const DocumentUpload = lazy(() => import('@zastrpay/kyc-documents').then((module) => ({ default: module.DocumentUpload })));
const ShopList = lazy(() => import('@zastrpay/shops').then((module) => ({ default: module.ShopList })));

export const App: React.FC = () => {
    const navigate = useNavigate();

    const { redirectSession, merchant } = useApp();
    const { state, customerId } = useAuth();
    const { openCookieBanner, analyticsEnabled, cookieConsent } = useCookieConsent();
    const { cancel, failure } = useSessionNavigation();

    useEffect(() => {
        trackPage('shop_finder');
    }, []);

    useEffect(() => {
        if (cookieConsent?.types?.includes('Essential')) {
            identifyUser(customerId, redirectSession?.id);
        } else {
            resetUser();
        }
    }, [cookieConsent, customerId, redirectSession]);

    return (
        <Routes>
            {/* Layout handles Suspense for the lazy loading so don't add it again in the routes */}
            <Route element={<Layout />}>
                {redirectSession?.type === 'NewCustomer' ? (
                    <>
                        <Route index element={<Navigate to="/register" replace />} />
                        <Route path="register/completed" element={<RegistrationCompleted />} />
                        <Route
                            path="register"
                            element={
                                <>
                                    <Registration />
                                    <Footer enable={analyticsEnabled} openCookieBanner={openCookieBanner} />
                                </>
                            }
                        />
                    </>
                ) : state !== 'authenticated' ? (
                    <>
                        <Route index element={<Navigate to="/login" replace />} />
                        <Route
                            path="login"
                            element={
                                <>
                                    <Login />
                                    <Footer enable={analyticsEnabled} openCookieBanner={openCookieBanner} />
                                </>
                            }
                        />
                        <Route path="login/*" element={<Navigate to="/login" replace />} />
                    </>
                ) : (
                    <>
                        <Route index element={<Navigate to="/intent" replace />} />
                        <Route path="intent" element={<TransactionIntentDetails />} />
                    </>
                )}

                <Route
                    path="change-phone"
                    element={<ChangePhone onChange={() => navigate('/')} onPinChange={() => navigate('/change-pin')} />}
                />
                <Route path="change-pin" element={<ChangePin onChange={() => navigate('/intent')} />} />
                <Route path="change-email" element={<ChangeEmail onChange={() => navigate('/intent')} />} />
                <Route path="account-recovery/:authFlowId" element={<ChangeAuthFactorQrCodePage />} />

                <Route path="kyc-request">
                    <Route index element={<KycRequestPage />} />
                    <Route path=":id" element={<CurrentKycRequest />}>
                        <Route
                            path="document-upload"
                            element={
                                <DocumentUpload
                                    onBack={() => navigate('/kyc-request')}
                                    onComplete={() => navigate('/intent')}
                                    onSkip={() => navigate('/intent')}
                                />
                            }
                        />
                        <Route
                            path="questionnaire"
                            element={<AnswerQuestionnaire onComplete={() => navigate('/intent')} onSkip={() => navigate('/intent')} />}
                        />
                        <Route
                            path="verify-email"
                            element={<VerifyEmail onVerify={() => navigate('/intent')} onSkipped={() => navigate('/intent')} />}
                        />
                    </Route>
                </Route>

                <Route path="support" element={<Support />} />
                <Route path="error">
                    <Route index element={<GeneralError merchant={merchant} onBack={failure} />} />
                    <Route path="network" element={<NetworkError merchant={merchant} onBack={failure} />} />
                    <Route path="expired" element={<SessionExpired merchant={merchant} onBack={cancel} />} />
                    <Route path="blocked" element={<CustomerBlocked merchant={merchant} onBack={cancel} />} />
                </Route>
            </Route>
            <Route path="shop-finder" element={<ShopList />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
